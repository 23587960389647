
















































































































































import { Component, Emit, Prop } from "vue-property-decorator";
import { Permission, Right } from "@/models/role";
import Rights, { RightTranslation } from "@/components/usermanagement/Rights";
import { xor } from "lodash";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { ApiUserManagementUser } from "@/models/user";
import { AccountType } from "@/enums/AccountType";
import DocumentProperties from "@/service/DocumentProperties";
import { DocumentCategory } from "@/enums/DocumentCategory";

@Component({})
@Translatable("usermanagement")
export default class UserManagementRights extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  @Prop({}) public user!: ApiUserManagementUser;

  private openExplanations: DocumentCategory[] = [];

  private documentProperties!: DocumentProperties;

  mounted() {
    this.documentProperties = DocumentProperties.getInstance();
  }

  private hasPermission(permission: Permission): boolean {
    return this.user.roles.includes(permission);
  }

  private getDocumentCategoriesByAccountType(
    accountType: AccountType
  ): DocumentCategory[] {
    switch (accountType) {
      case AccountType.COMPANY:
        return [
          DocumentCategory.FINANCIAL,
          DocumentCategory.FISCAL,
          DocumentCategory.HR,
          DocumentCategory.OTHER,
        ];

      case AccountType.PERSON:
        return [DocumentCategory.FISCAL, DocumentCategory.OTHER];
      default:
        return [];
    }
  }

  private onExplanationClick(documentCategory: DocumentCategory) {
    this.openExplanations = xor(this.openExplanations, [documentCategory]);
  }

  get getRights(): RightTranslation[] {
    return Rights.getRights();
  }

  @Emit("input")
  private onChange(
    documentCategory: DocumentCategory,
    right: RightTranslation
  ): { documentCategory: DocumentCategory; right: Right | null } {
    return {
      documentCategory: documentCategory,
      right: right.value,
    };
  }
}
