import { Right } from "@/models/role";

export type RightTranslation = {
  label: string;
  value: Right | null;
  id: number;
};

export default class Rights {
  static getRights(): RightTranslation[] {
    return [
      {
        label: "views.usermanagement.user_management_detail.noAccess",
        value: null,
        id: 0,
      },
      {
        label: "views.usermanagement.user_management_detail.canView",
        value: "read",
        id: 1,
      },
      {
        label: "views.usermanagement.user_management_detail.canApprove",
        value: "approval",
        id: 2,
      },
      {
        label: "views.usermanagement.user_management_detail.mustApprove",
        value: "mandatory_approval",
        id: 3,
      },
    ];
  }
}
